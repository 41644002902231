<template>
  <div class="container">
    <div class="banner">
        <div class="w solution-bg">
            <h3>产品服务理念</h3>
            <p>高效的服务流程,高规格的服务品质塑造一流服务理念!<br />一切为客户着想,为客户的企业发展为自身企业发展的唯一检验标准!!!</p>
            <button @click="showKefuBox">在线咨询&nbsp;<i class="icon-im"></i></button>
        </div>
    </div>
    <!--//产品分类-->
    <div class="product">
        <div class="w">
            <h3 class="t">服务产品范围</h3>
            <ul>
                <li class="show"><span><i class="icon iconfont">&#xe811;</i></span><h3>品牌设计</h3><p>提升企业的品牌与产品价值</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe87d;</i></span><h3>IT技术服务</h3><p>构建企业IT互联网服务基础</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe82a;</i></span><h3>电商服务</h3><p>打造互联网电子商务服务和发展</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe757;</i></span><h3>营销推广</h3><p>让世界更快/更直接/更有效的与您同行</p><div class="show_bg">&nbsp;</div></li>
                <li><span><i class="icon iconfont">&#xe70e;</i></span><h3>科技服务</h3><p>产品的附加价值是企业终身的成果</p><div class="show_bg">&nbsp;</div></li>
            </ul>
        </div>
    </div>
    <div class="product-item">
        <div class="w">
            <ul class="show">
                <li><div class="img"><img src="../assets/logosheji.png" /></div><h3>LOGO设计</h3></li>
                <li><div class="img"><img src="../assets/vi.png" /></div><h3>VI设计</h3></li>
                <li><div class="img"><img src="../assets/xuanchuanpin.png" /></div><h3>宣传品设计</h3></li>
                <li><div class="img"><img src="../assets/guanggaosheji.png" /></div><h3>广告设计</h3></li>
                <li><div class="img"><img src="../assets/baozhuangsheji.png" /></div><h3>包装设计</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../assets/wangzhansheji.png" /></div><h3>网站建设</h3></li>
                <li><div class="img"><img src="../assets/xiaochengxu.png" /></div><h3>小程序开发</h3></li>
                <li><div class="img"><img src="../assets/gongzhonghao.png" /></div><h3>微信开发</h3></li>
                <li><div class="img"><img src="../assets/app.png" /></div><h3>APP开发</h3></li>
                <li><div class="img"><img src="../assets/ruanjiankaifa.png" /></div><h3>软件开发</h3></li>
                <li><div class="img"><img src="../assets/itzonghezhili.png" /></div><h3>IT综合服务</h3></li>
                <li><div class="img"><img src="../assets/shujuzhili.png" /></div><h3>数据服务</h3></li>
                <li><div class="img"><img src="../assets/uisheji.png" /></div><h3>UI设计</h3></li>
                <li><div class="img"><img src="../assets/yunfuwu.png" /></div><h3>云服务</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../assets/dianshangsheji.png" /></div><h3>电商设计</h3></li>
                <li><div class="img"><img src="../assets/dianshangcehua.png" /></div><h3>电商策划</h3></li>
                <li><div class="img"><img src="../assets/dianshangtuiguan.png" /></div><h3>电商推广</h3></li>
                <li><div class="img"><img src="../assets/dianshangyunyin.png" /></div><h3>电商运营</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../assets/yinxiaocehua.png" /></div><h3>营销策划</h3></li>
                <li><div class="img"><img src="../assets/wangluomingpian.png" /></div><h3>网络名片</h3></li>
                <li><div class="img"><img src="../assets/meijieyunying.png" /></div><h3>媒介运营</h3></li>
                <li><div class="img"><img src="../assets/jingzhunyinliu.png" /></div><h3>精准引流</h3></li>
                <li><div class="img"><img src="../assets/xuanchuanpian.png" /></div><h3>宣传片</h3></li>
            </ul>
            <ul>
                <li><div class="img"><img src="../assets/gaoqirenzhen.png" /></div><h3>高企认定</h3></li>
                <li><div class="img"><img src="../assets/ruanzhushenqin.png" /></div><h3>软著申请</h3></li>
                <li><div class="img"><img src="../assets/shangbiaoshenqin.png" /></div><h3>商标申请</h3></li>
                <li><div class="img"><img src="../assets/zhuanlishenqin.png" /></div><h3>专利申请</h3></li>
                <li><div class="img"><img src="../assets/iso9001.png" /></div><h3>9001认证</h3></li>
                <li><div class="img"><img src="../assets/shuanruanrenzhen.png" /></div><h3>双软评估</h3></li>
                <li><div class="img"><img src="../assets/zijinfuchi.png" /></div><h3>资金扶持</h3></li>
                <li><div class="img"><img src="../assets/zizhirenzhen.png" /></div><h3>资质认证</h3></li>
                <li><div class="img"><img src="../assets/kexinxinyanjiubaogao.png" /></div><h3>项目可研报告</h3></li>
                <li><div class="img"><img src="../assets/shangyejihuashu.png" /></div><h3>商业计划书</h3></li>
            </ul>
            <div class="clearfix"></div>
        </div>
    </div>
    <!--//在线提交咨询-->
    <div class="w aswer">
        <h3 class="t">在线提交咨询</h3>
        <p class="d">全流程闭环对客户合作项目进行实时、准确的管理和运作，对项目的整个过程监控预防项目风险客户关心的就是我们一直高度关心的！</p>
        <div class="clearfix"></div>
        <div class="qrcode"><img src="../assets/qr_customer.png" /><span>在线客服</span><img src="../assets/qr_compay.png" /><span>企业公众号</span></div>
        <div class="form">

            <strong> 服务项目：</strong>
            <span><input type="text" /></span>
            <strong>联系人：</strong>
            <span><input type="text" /></span>
            <strong>联系电话：</strong>
            <span><input type="text" /></span>
            <strong>所在地区：</strong>
            <span><input type="text" /></span>
            <div class="clearfix"></div>
            <strong>咨询内容：</strong><span style="width:80%;"><textarea rows="5" cols="10"></textarea></span>
            <div class="clearfix"></div>
            <strong>附件上传：</strong><span style="width:80%;"><button>上传附件</button>&nbsp;&nbsp;仅限制8M以内,且格式为png/jpg/gif/doc/excel</span>
            <div class="clearfix"></div>
            <strong>&nbsp;</strong><span><button>立即提交</button></span>
            <div class="clearfix"></div>
        </div>
        <div class="clearfix"></div>
    </div>
    <!--//与更多优秀企业一起告诉成长-->
    <div class="ads-index">
      <h3>与更多优秀企业一起高速成长</h3>
      <p>通过领先的技术和严谨的管理，赢得客户的好评，并结成战略合作关系</p>
    </div>
    <!--//客户案例-->
    <div class="clients-index"><img src="../assets/clients.png" /></div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
    mounted: function() {
        $(function(){
            $(".product ul li").hover(function(){
                $(".product ul li.show").removeClass("show");
                $(this).addClass('show');

                $('.product-item ul.show').removeClass("show");
                $('.product-item ul').eq($(this).index()).addClass("show");
            });
        });
    },
    methods: {
        showKefuBox: function()
        {
            this.$store.commit("setKefuStatus",true);
        }
    }
}
</script>

<style>
.banner h3{ font-size: 36px; color: #ffffff; font-weight: normal; padding-top: 60px; margin:0px;}
.banner p{ font-size: 18px; color: #ffffff; line-height: 30px;}
.banner button{ background-color: #ffffff; border: 1px #ffffff solid; border-radius: 3px; padding: 10px 40px; font-size: 18px; cursor: pointer; margin-top: 30px;}
.banner button i{ font-size: 30px; }
.solution-bg{ background: url("../assets/so_develop_f.png") right bottom no-repeat; height: 90%;}

.product{ height: 320px; position: relative; z-index: 9999;}
.product h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.product ul,.product ul li,.product ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block; line-height: 30px;}
.product ul{  height: 320px;}
.product ul li{ width: 19%; float: left; text-align: center; height: 230px; padding: 40px 1px 1px 0px; cursor: pointer; color: #000000; cursor: pointer;}
.product ul li .show_bg{ display: none;}
.product ul li.show{ border: 1px #eeeeee solid; padding: 40px 0px 0px; border-radius: 5px; background-color: #ffffff; position: relative;}
.product ul li.show .show_bg{ height: 25px; background:url("../assets/show_bg.png") bottom center no-repeat; position: absolute; bottom: -25px; width: 100%; display: block;}
.product ul li span i{ font-size: 45px;}
.productn ul li:hover{ color: #004172;}
.product ul li h3{ font-size: 18px; font-weight: normal;}
.product ul li p{ font-size: 14px;}

.product-item{ background-color: #edecec; padding: 50px 0px; position: relative; z-index: 888; margin-top: -30px;}
.product-item ul,.product-item ul li,.product-item ul li h3{ margin: 0px; padding: 0px; list-style: none; display: block;}
.product-item ul{ margin-top: 50px; display: none;}
.product-item ul li{ width: 18%; float: left; margin-right: 20px; margin-bottom: 20px;}
.product-item ul li .img{ height: 250px; background-color: #ffffff; border-radius: 5px; overflow: hidden;}
.product-item ul li h3{ line-height: 30px; text-align: center; padding-top: 10px;}
.product-item ul.show { display: block;}

.aswer{ margin-bottom: 50px;}
.aswer h3.t{ font-size: 25px; font-weight: normal; margin-top: 50px;}
.aswer .d{ font-size: 1rem;}
.aswer .form{ font-size: 1rem; float: left; width: 70%; padding-top: 50px;}
.aswer .form strong,.aswer .form span{ display: block; float: left; font-size: 0.9rem; font-weight: normal; margin-bottom: 10px;}
.aswer .form strong{ width: 10%; text-align: right; padding-top: 5px}
.aswer .form span{ width: 40%;;}
.aswer .form span input{ width: 80%; line-height: 30px; border: 1px #cccccc solid; border-radius: 5px; font-size: 1rem;}
.aswer .form span textarea{ border:1px #cccccc solid; width: 103%; border-radius: 5px;}
.aswer .form span button{ background-color: #036eb8; padding: 10px 30px; border: 1px #036eb8 solid; color: #ffffff; border-radius: 5px; font-size: 0.9rem; font-weight: normal; cursor: pointer;}
.aswer .qrcode{ float: right; width: 250px; border-left: 1px #eeeeee solid; text-align: center;}
.aswer .qrcode img{ margin-bottom: 5px; border: 1px #eeeeee solid; padding: 5px;}
.aswer .qrcode span{ display: block; line-height: 30px;}

.ads-index{ background-color: #e8e7e7; padding: 50px 0px; margin: 50px 0px;}
.ads-index h3,.ads-index p{ text-align: center; margin: 0px; padding: 0px;}
.ads-index h3{ font-size: 25px; font-weight: normal;}
.ads-index p{ font-size: 18px;}

.clients-index{ text-align: center; padding-bottom: 50px;}
</style>
